/* This module is unused in favor of integration with dirrect call */






// import WS from "./websocket"
import LocalStorage from "./local_storage";
import APIDef from "../config/api_def";

class APIServer{
    // constructor(){
    //     this.websocket = new WS(); 
    //     // console.log("build APIServer");
    // }
    // Open(reason){
    //     this.websocket.Open(reason);
    // }
    // Close(reason){
    //     this.websocket.Close(reason);
    // }

    static AUTH_TOKEN = LocalStorage.Load(LocalStorage.FileList.token);
    static address = APIDef.Address.completion + "?" + this.AUTH_TOKEN;

    static async stream(model, message, prompt_type){
        const body = {
            model:model,
            message:message,
            prompt_type:prompt_type,
        }
        const packet = {
            method  : 'POST',
            headers : {'Content-Type': 'application/json',},
            // headers : {'Content-Type': 'text/plain',},
            body    : JSON.stringify(body),
            // model   : 'cors',
        }
        console.log(this.address);
        const reply = await fetch(this.address, packet);
        
        console.log(reply);
    
        // console.log(await reply.text());
        const chunk_reader = reply.body.getReader() ;
        const decoder      = new TextDecoder('utf-8');
        while (true){
            const {value, done} = await chunk_reader.read();   // has to use label value and done
            if (done){break;}
            // console.log(decoder.decode(value, {stream: true}));
            console.log(decoder.decode(value));
        }
    
    
    
        // const packet = {
        //     method: 'POST',
        //     headers: { 'Content-Type': 'text/plain' },
        //     body: "Data here"
        // };
        
        // fetch("https://server.entrosystem.com:445/completion?put_the_token_api_here", packet)
        //     .then(response => {
        //         const reader = response.body.getReader();
        //         const decoder = new TextDecoder('utf-8');
        //         function readChunk() {
        //             return reader.read().then(({ value, done }) => {
        //                 if (done) {
        //                     console.log('Stream complete');
        //                     return;
        //                 }
        //                 const chunk = decoder.decode(value, { stream: true });
        //                 console.log(chunk);
        //                 return readChunk();
        //             });
        //         }
        
        //         return readChunk();
        //     })
        //     .catch(error => {
        //         console.error('Error:', error);
        //     });
    
    
    }
    

}

export default APIServer;







