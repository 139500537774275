import React, { createContext, useState, useEffect } from 'react';



export const ChatInput = createContext();
export function ChatInputProvider({children}){


    /* Provide context for user text input */
    const [user_input, set_user_input] = useState("");


    /* Provide context for model selection dropdown choice */

    let models = {
        list : [],
        chosen_model : "",
    };
    const [model_list, set_model_list] = useState(models);


    /* Provide context for thread/ session selector in the right sidebar */
    let ses_title = {
        active_thread:-1,
        session_list :[],
    };
    const [session_title, set_session_title] = useState(ses_title);


    /* Provide context for new session/ thread button */
    let NS={
        new_session_clicked: false,
        new_session_established: false
    };
    const [new_session, set_new_session] = useState(NS);


    /* Provides context for chat thread/ session dropdown option (delete, rename) */ 
    // let session_dropdown_init = [["Delete", "Rename"], -1, "NO"];                     // [[Command list], chosen command, confirm] => chosen command(0,1), confirm ("NO" = No, "YES" = yes)
    let session_dropdown_init = {
        // opened_index : -1,
        options : ["Delete", "Rename"],
        chosen  : -1,                   // index of chosen command, -1 if none chosen
        conf    : "NO",                 // confirmed, value = yes/no
        del_indx: -1                    // index of the thread from delete command, use this context for compactness, -1 if none chosen
    }
    const [session_dropdown_choice, set_session_dropdown_choice] = useState(session_dropdown_init);




    return (
        <ChatInput.Provider value={{user_input, set_user_input, model_list, set_model_list, session_title, set_session_title, new_session, set_new_session, session_dropdown_choice, set_session_dropdown_choice}}>
            {children}
        </ChatInput.Provider>
    );
}