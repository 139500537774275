import './text_input.css'
import Buttons from './buttons';

import React, { useState, useRef, useEffect, useContext} from 'react';
import { ChatInput } from "../../contexts/inputs";


function TextInput(){
    
    const [input, setInput] = useState('');

    const {set_user_input} = useContext(ChatInput);
    
    const Button = useRef(new Buttons()).current;
    const textInputArea = useRef(null);



    useEffect(() => {
        if (textInputArea.current) {
            textInputArea.current.style.height = '3vh';
            // textAreaRef.current.style.height = '48px';
            textInputArea.current.style.height = `${textInputArea.current.scrollHeight}px`;
            const root = document.documentElement;

            let scrollHeight = textInputArea.current.scrollHeight+15;
            if (scrollHeight > 400){
                scrollHeight = 400;
                textInputArea.current.style.height = `${385}px`;
            }
            root.style.setProperty('--textbox-height', `${scrollHeight}px`);
            // console.log(textAreaRef.current.style.height)
        }
    }, [input]);

    function SendMessage(){
        const reply_time = new Date().toISOString();
        const user_input = {
            text:input,
            time:reply_time
        }
        set_user_input(user_input);
        setInput('');
    };

    return(
        <div className="text_input">
            <div className='text_input_child1' style={{opacity: (input.length>0)? 1: undefined}}>
                <textarea ref={textInputArea} value={input} onChange={(e) => setInput(e.target.value)} 
                        onKeyDown={(e) => {
                            if (e.key === 'Enter' && !e.shiftKey) {
                                e.preventDefault();
                                SendMessage();
                            }}} className='text_input_child2'/>
                <div className='send_button'> {Button.SendButton()}</div>
                <div className='attachment_button'>{Button.AttachButton()}</div>
            </div>
        </div>
    );
}

export default TextInput;