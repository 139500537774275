

import React, { useState, useRef, useEffect, useContext } from 'react';
import useResponsiveDimensions from '../../hooks/handle_resize.js'; 
import './layout.css'
import Header from './header.js';
import RSidebar from './rsidebar.js';
import LSidebar from './lsidebar.js';
import Footer from './footer.js';
import Workspace from './workspace.js';

// const main_layout = () => {
function MainLayout(){    
    useResponsiveDimensions(); // This will set up the dimensions and listen to resize


    return(
        <div className="main_layout">
            <RSidebar/>
            <LSidebar/>
            <Workspace/>
            <Header/>
            <Footer/>
            <div>
                
            </div>
        </div>

    )
}

export default MainLayout;