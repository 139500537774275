import styles from './buttons.module.css'


class Buttons{
    constructor(){

    }
    SendButton(){
        return(
            // {/* <path fill="currentColor" d="M12 5l-9 9h6v5h6v-5h6z" /> */}
            < GeneralButton d="M21 56c6,-13 18,-34 30,-33 13,1 26,22 31,35 5,13 1,16 -3,17 -4,1 -7,0 -12,-5 -5,-6 -10,-16 -17,-15 -7,1 -15,12 -21,18 -6,6 -9,6 -11,3 -2,-2 -3,-6 3,-19z" />
        );
    }
    AttachButton(){
        return(
            <GeneralButton d="M28 19c16,-10 45,-14 44,-7 -1,7 -30,24 -38,37 -8,13 5,22 20,16 14,-6 30,-26 36,-24 6,1 2,25 -10,37 -13,13 -35,15 -50,10 -14,-5 -21,-16 -22,-30 -1,-14 4,-29 20,-39z"/>
        );
    }
    DropDownButton(){
        return(
            <GeneralButton width="30px" d="M23 51c-3,-12 -5,-29 -2,-27 3,2 11,22 18,32 6,10 11,10 18,0 6,-10 14,-30 18,-33 3,-2 2,13 -1,25 -3,12 -7,20 -11,26 -5,6 -9,10 -14,10 -5,0 -9,-5 -13,-11 -4,-6 -8,-12 -11,-23z"/>
        );
    }

    Comon

    
}
export default Buttons;


export function GeneralButton({ width = "40px", height = "40px", viewBox = "0 0 100 100", d, className = styles.general_button }) {
    return (
        <div className={className}>
            <svg width={width} height={height} viewBox={viewBox}>
                <path fill="currentColor" d={d} />
            </svg>
        </div>
    );
}
