// start only the frontend :npm start
// When i run "npm run build" all the js and ConfigData.json will be bundled in main.js(build/static/js folder)
// CI=false npm run build

import React, { createContext, useContext, useState, useEffect } from 'react';
import MainLayout from './components/layout/main_layout.js';

function App() {    
    return (
    <div className="app" >
        {<MainLayout/>}
    </div>
    );
}
export default App;