import HTTPSChunk from "./https_chunk";
import APIDef from "../config/api_def";
import LocalStorage from "./local_storage";

const ChunkHTTPS = new HTTPSChunk();


class AuthenticationService{
    constructor(){

    }

    static async Register(user_name, password){
        let data={
            user_name:user_name,
            password:password
        }
        console.log(data);
        let register_status;
        try{
            register_status = await ChunkHTTPS.POST(ChunkHTTPS.dest.register, data);
        }catch(error){
            // console.error(`ChunkHTTPS.POST error : ${error}`);
            throw new Error (`ChunkHTTPS.POST Register error status : ${error}`);                   // stop execution at this line and send up the new error
        }

        console.log("Register status", register_status.UserRegister_reply_state);
        if (register_status.UserRegister_reply_state === APIDef.UserRegister_reply_state.Username_successfully_registered){
            try{
                await this.Login (user_name, password);
            }catch(error){
                throw new Error(`Register at login step error status : ${error}`);
            }
        }else{
            throw new Error (`UserRegister_reply_state error : ${register_status.UserRegister_reply_state}`);
        }
    }

    static async Login(user_name, password){
        // console.log(navigator.deviceMemory, navigator.languages, navigator.hardwareConcurrency, navigator.getBattery, window.screen, performance.now());
        // console.log(navigator.userAgent)
        let data={
            login_type:APIDef.login_type.new_login,
            user_name:user_name,
            password:password,
            token_spice:[navigator.languages, navigator.hardwareConcurrency, window.screen.width, window.screen.height, window.screen.colorDepth, window.screen.pixelDepth].toString()
        }
        console.log(data);
        let login_reply;
        try{
            login_reply = await ChunkHTTPS.POST(ChunkHTTPS.dest.login, data);
        }catch(error){
            throw new Error (`ChunkHTTPS.POST Login error status:${error}`);
        }
        if (login_reply.AuthenticationLogin_reply_state === APIDef.AuthenticationLogin_reply_state.Succesfull_login){
            console.log("succes_login", login_reply.AuthenticationLogin_reply_state, login_reply.token);
            LocalStorage.Store(login_reply.token, LocalStorage.FileList.token);
        }
    }
}

export default AuthenticationService;