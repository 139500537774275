


import './layout.css'

function RSidebar(){
    return(
        <div className="r_sidebar"></div>
    );
}

export default RSidebar