import {useRef, useContext, useState, useEffect} from 'react';


import styles from "./session_list.module.css"
import Random from "../../utils/random";
import { ChatInput } from '../../contexts/inputs';
import ReactDOM from 'react-dom';
import { Translations } from 'openai/resources/audio/translations.mjs';



function SessionList(){
    const {session_title, set_session_title} = useContext(ChatInput);                       // Thread/ session list global context
    const {session_dropdown_choice, set_session_dropdown_choice} = useContext(ChatInput);   // Context for dropdown menu in each thread title
    
    const [session_drop, set_session_drop] = useState(-1);              // Index to track which thread/ session title is being focused (not only hovered) -1 means no dropdown anywhere
    // const [session_option_hover, set_session_option_hover] = useState(-1);
    // const [delete_session_confirm_box, set_delete_session_confirm_box] = useState(false);


    const random                    = useRef(new Random()).current;        // class to produce dummy thread/ session name
    const session_option_drop_ref   = useRef(null);        // Reference for the drop down menu in each thread (option delete, rename, etc)
    const delete_confirm_box_ref    = useRef(null);        // ref for delete confirm box
    const session_main_ref          = useRef(null);        // ref for main body of thread/ session selector
    const session_option_ref        = useRef(null);        // ref for spawner of option dropdown session_option_drop_ref
    const session_list              = useRef([]);                     // Dummy session/ thread for dev
    const SessionDropdown_index     = useRef(-1);           // Hold the index of session/ thread that is highlighted for delete, rename, etc

    // console.log("rend");

    useEffect(()=>{
        // console.log(session_dropdown_choice);
        for(let j =0; j<30;j++){
            let T = random.generateRandomString(25);
            session_list.current.push(T);
        }
        return()=>{
            document.removeEventListener('mousedown', AfterSessionClick);
            document.removeEventListener('mousedown', AfterDeleteBoxClick);
        };
    },[]);


    /* This part of code manage drop down menu beside thread title */

    function SessionClick(index, type){
        // console.log(index);
        if (type==="OPTION"){
            set_session_drop(index);
            SessionDropdown_index.current=index;
            document.addEventListener('mousedown', AfterSessionClick);
        }else if (type==="MAIN"){
            // console.log("switch to", index);
            set_session_title(prev=>({...prev, active_thread:index}) );
        }
    }

    function AfterSessionClick(click_event){
        if (session_option_drop_ref.current && !session_option_drop_ref.current.contains(click_event.target)){
            set_session_drop(-1);
            document.removeEventListener('mousedown', AfterSessionClick);
            SessionDropdown_index.current=-1;
        }
    }

    function SessionDropdownClick(choice){
        if (SessionDropdown_index !==-1){
        // if (session_drop !==-1){
            // let ses_drop = [session_dropdown_choice[0], choice, "NO"];
            // set_session_dropdown_choice(ses_drop);
            set_session_dropdown_choice(previous_value=>({...previous_value, chosen:choice}));
            console.log(session_dropdown_choice['options'][choice]);

            set_session_drop(-1);
            document.removeEventListener('mousedown', AfterSessionClick);
            // SessionDropdown_index.current=-1;
        }
    }
    
    function RenderSessionDropdown(){
        // console.log(session_dropdown[0]);
        return(
            <div>{session_dropdown_choice['options'].map((option, index) => 
                <div key={index} className={styles.session_dropdown} onClick={()=>SessionDropdownClick(index)} >
                    {option}
                </div>)}
            </div>
        );
    }



    /* This part of code manage delete thread action  */

    function DeleteConfirm(input){
        let index = SessionDropdown_index.current;
        console.log("sess_drop", index);
        if (input == 0){
            // console.log("no");
            set_session_dropdown_choice(previous_value=>({...previous_value, chosen:-1}));
        }else{
            set_session_dropdown_choice(previous_value=>({...previous_value, conf:"YES", del_indx:index, chosen:-1}));
            // console.log("yes");
        }

        SessionDropdown_index.current=-1;
        document.removeEventListener('mousedown', AfterDeleteBoxClick);
    }

    // const DeleteConfirmBox = ({})=>{
    function DeleteConfirmBox(){
        document.addEventListener('mousedown', AfterDeleteBoxClick);
        return ReactDOM.createPortal(
            <div className={styles.delete_session_confirm_box_mask}>
                <div className={styles.delete_session_confirm_box} ref={delete_confirm_box_ref}>
                    <p style={{transform: 'translateX(9%)', fontSize: '130%'}}>Are you sure to delete ?</p>
                    <p style={{transform: 'translateX(9%)', fontSize: '70%'}}>The thread will be moved to archive</p>
                    <div className={styles.confirm_button} style={{transform: 'translate(100%, 80%)'}} onClick={()=>DeleteConfirm(0)}>
                        <p style={{transform: 'translate(0%,-60%)',textIndent:'30%', fontSize: '130%'}}>NO</p>
                    </div>
                    <div className={styles.confirm_button} style={{transform: 'translate(250%, 80%)', backgroundColor:'rgb(200,20,20)'}} onClick={()=>DeleteConfirm(1)}>
                        <p style={{transform: 'translate(0%,-60%)', textIndent:'30%', fontSize: '130%'}}>YES</p>
                    </div>
                </div>
            </div>, document.body
        );
    }
    function AfterDeleteBoxClick(click_event){
        if (delete_confirm_box_ref.current && !delete_confirm_box_ref.current.contains(click_event.target)){
            SessionDropdown_index.current=-1;
            // let ses_drop = [session_dropdown_choice[0], -1];
            // set_session_dropdown_choice(ses_drop);
            set_session_dropdown_choice(previous_value=>({...previous_value, chosen:-1}));
            document.removeEventListener('mousedown', AfterDeleteBoxClick);
        }
    }





    /* Using l mouse enter and leave on js is unoptimized, look for way to do it in css */
    return(
        <div className = {styles.session_list1} >
            {session_title["session_list"].map((session, index)=><div key={index} className={styles.session}>
            {/* {session_list.current.map((session, index)=><div key={index} className={styles.session}  onMouseEnter={()=>Enter_session_option(index)} onMouseLeave={Leave_session_option}  style={{background:(session_option_hover===index || session_drop===index)? 'rgb(40, 40, 40)': 'rgb(30, 30, 30)'}}> */}
            {/* {session_list.current.map((session, index)=><div key={index} className={styles.session} ref={session_main_ref}> */}
                {session}
                {/* <span>{session}</span>  */}
                {(session_drop===index) && <div className={styles.session_option_drop} ref = {session_option_drop_ref}>
                    <div className={styles.session_option_mask}></div>
                    <RenderSessionDropdown/>
                </div>}
                <div className={styles.session_option} onClick={()=>SessionClick(index, "OPTION")} ref={session_option_ref}>
                    <svg width = "40px" height = "40px" viewBox = "0 0 100 100"  xmlns="http://www.w3.org/2000/svg">
                        <circle cx="30" cy="45" r="5" fill="white"/>
                        <circle cx="50" cy="45" r="5" fill="white"/>
                        <circle cx="70" cy="45" r="5" fill="white"/>
                    </svg>
                </div>
                <div className={styles.session_mask} onClick={()=>SessionClick(index, "MAIN")}></div>

            </div>)}
            {session_dropdown_choice['chosen']===0 && <DeleteConfirmBox/>}
        </div>
    );
}


export default SessionList;










/* deprecated  way to manipulate css from js*/
    // function Enter_session_option(hover_event){
    //     set_session_option_hover(hover_event);
    //     // session_option_hover.current = hover_event;
    // }
    // function Leave_session_option(){
    //     set_session_option_hover(-1);
    //     // session_option_hover.current = -1;
    // }


    // let session_list = []
    // for(let j =0; j<30;j++){
    //     let T = random.generateRandomString(25);
    //     session_list.push(T);
    // }

    
    // return(
    //     <div className = {styles.session_list1} >
    //         {/* {session_title["session_list"].map((session, index)=><div key={index} className={styles.session}> */}
    //         {/* {session_list.current.map((session, index)=><div key={index} className={styles.session}  onMouseEnter={()=>Enter_session_option(index)} onMouseLeave={Leave_session_option}  style={{background:(session_option_hover===index || session_drop===index)? 'rgb(40, 40, 40)': 'rgb(30, 30, 30)'}}> */}
    //         {session_list.current.map((session, index)=><div key={index} className={styles.session}>
    //             {session}
    //             {/* <span>{session}</span>  */}
    //             {/* <div className={styles.session_option}  style={{display:(session_option_hover===index || session_drop===index)?'block':'none'}} onClick={()=>SessionClick(index)}> */}
    //             <div className={styles.session_option} onClick={()=>SessionClick(index)}>
    //                 <svg width = "40px" height = "40px" viewBox = "0 0 100 100"  xmlns="http://www.w3.org/2000/svg">
    //                     <circle cx="30" cy="45" r="5" fill="white"/>
    //                     <circle cx="50" cy="45" r="5" fill="white"/>
    //                     <circle cx="70" cy="45" r="5" fill="white"/>
    //                 </svg>
    //                 {/* {(session_drop===index) && <div className={styles.session_option_drop} ref = {session_option_drop_ref}> </div>} */}
    //                 {(session_drop===index) && <div className={styles.session_option_drop} ref = {session_option_drop_ref}> </div>}
    //             </div>
    //         </div>)}
    //     </div>
    // );