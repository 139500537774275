import { useState, useEffect, useContext , useRef} from 'react';

import { ChatInput } from '../../contexts/inputs';
import Buttons from './buttons';
import './drop_down.css'






function DropDown(){
    const [drop, set_drop] = useState(false);               // hold bool state if the option is dropping
    const [dropHoover, setdropHoover] = useState(false);    // hover state
    const [active_model, set_active_model] = useState("");  // active model state, for display

    const {model_list, set_model_list} = useContext(ChatInput);     // global context to list model
    
    const Button = useRef(new Buttons()).current;
    const DropDownRef = useRef(null);

    useEffect(()=>{
        if (model_list){
            // set_render_list(true);
            set_active_model(model_list["chosen_model"])
            // console.log(model_list);
        }
    },[model_list])


    function mouse_enter_area(){setdropHoover(true);}
    function mouse_leave_area(){setdropHoover(false);}


    useEffect(()=>{
        return()=>{
            document.removeEventListener('mousedown', one_click);           // will do nothing if it's already executed elsewhere 
        };
    },[]);

    

    function one_click(event){
        // setTimeout(() => {
        //     document.removeEventListener('mousedown', one_click);
        //     set_drop(false);
        // },300);

/*         DropDownRef.current: This checks if the current property of DropDownRef is truthy, which means the ref is attached to a DOM element. If DropDownRef.current is null, the rest of the condition is skipped, ensuring that no errors occur trying to call .contains on null.

        !DropDownRef.current.contains(event.target): The .contains method is a standard DOM API that checks if a node is a descendant of another node. In this context, it checks if the element that was clicked (event.target) is outside of the dropdown element (DropDownRef.current). If event.target is not a descendant of the dropdown, the condition evaluates to true.

        If the condition is true, it means a click occurred outside the dropdown, and typically, you would then execute code to hide the dropdown.
        If the condition is false, it means the click occurred inside the dropdown, and you would typically not want to hide it. */
        if (DropDownRef.current && !DropDownRef.current.contains(event.target)){
            set_drop(false);
            document.removeEventListener('mousedown', one_click);
        }
    }
    function drop_down_click(){
        set_drop(!drop);
        // document.addEventListener('mousedown', one_click, {once:true}); 
        document.addEventListener('mousedown', one_click); 
    }

    function ChoseModel(model_name){
        let models = {
            list : model_list["list"],
            chosen_model : model_name,
        };
        set_model_list(models);
        set_drop(false);
        // console.log(model_list["list"].indexOf(model_name)); 
    }


    function Render_model_list(data){
        let model_name = data;
        // console.log(model_name.data);
        return( 
            <div className='model_name' onClick={(e)=>{
                e.stopPropagation();
                ChoseModel(model_name.data);
            }} >{model_name.data}</div>
            // <div className='model_name'  >{model_name.data}</div>
        );
    }
    return (
        <div className= "drop_down1">
            {/* <div className='drop_down2' onClick={drop_down_click} onMouseEnter={mouse_enter_area} onMouseLeave={mouse_leave_area} style={{opacity: (drop||dropHoover) ? 0.8 : 0}}> */}
            <div className='drop_down2' onClick={drop_down_click} onMouseEnter={mouse_enter_area} onMouseLeave={mouse_leave_area} style={{backgroundColor: (drop||dropHoover) ? 'rgb(40,40,40)' : 'transparent'}}>
                <div className='drop_down_2_icon'>{Button.DropDownButton()}</div>
                <div  className='drop_down2_text'><p style={{ userSelect: 'none' }}>{active_model}</p></div>
                
            </div> 
            {drop && <div className='drop_down_float' ref={DropDownRef}>
                {/* {render_list && <div>{model_list["list"].map((model) => <Render_model_list key={model} data={model}/>)}</div>} */}
                <div>{model_list["list"].map((model) => <Render_model_list key={model} data={model}/>)}</div>
            </div>}
        </div>
    );
}



export default DropDown;



// export function Render_model_list(data){
//     let model_name = data;
//     console.log(model_name.data);
//     return( 
//         <div className='model_name'>{model_name.data}</div>
//     );
// }