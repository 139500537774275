import Random from "../../utils/random";


const rand = new Random();
/* agentID, 0 is userEvent, 1 is LLM agent 

active_node = 1  means the current node is the active one (this node up is the sequence that is sent to prompt)

*/



export class Node{

    constructor(parent, parentID, Model, agent_id, Message, Time, active_node = 0, nodeID = 0){
        this.children = [];
        this.parent   = parent;
        this.parentID = parentID

        this.model   = Model;
        this.agentID = agent_id;
        this.message = Message;
        this.time    = Time;
        this.active_status = active_node;           // this logic is unused currently, replaced by root.active node
        this.depth   = parent.depth + 1;
        if (nodeID === 0){
            this.nodeID = rand.generateFastUniqueId();
        }else{
            this.nodeID = nodeID;
        }
        
    }

    clean_up(){
        while (this.children.length>0){
            let child = this.children.pop();
            child.clean_up();
        }
        this.parent=null;
    }
}



/* 
The first node wil be the root node. Root node is not the first conversation turn, but it's parent. 

this.current_model   > hold current active model, might change latter but the unique value will be embedded to each model's answer
*/
class ConversationRoot{
    constructor(Model, Username){
        /* Dynamic variable */
        this.children=[];
        this.threadTitle=[];            // thread tittle, query to small llm for summary of the first conversation turn
        this.active_node=this;          // pointer to current active node, if in root, refer to active thread
        this.active_thread=-1;           // index of the current thread that is active
        this.prompt = [];
        // this.prompt = "";

        /* absolute Constant */
        this.nodeID = 12345;
        this.parentID = 0;
        this.depth = 0;                 // the first node (user) has depth 1, the first llm reply has depth 2
        /* pseudo constant */
        this.username = Username;
        this.current_model = Model;
    }

    // "messages": [
    //     {"role": "user", "content": "Who are you?"},
    //     {"role": "assistant", "content": "I'm not sure, but my best guess is"},]
    build_prompt(new_message){
        if (this.active_node.depth% 2 === 0 ){
            let message="";
            for (let m of new_message){
                message+=m;
            }
            // this.prompt += "Agent : \n" + message + "\n";
            // this.prompt += message + "\n";
            this.prompt.push({"role":"assistant", "content":message});
        }else{
            // this.prompt += "User : \n" + new_message + "\n" + "Agent : \n";
            this.prompt.push({"role":"user", "content":new_message});
        }
    }
    

    add_children(agent_id, Message, Time) {
        if (this.active_node === this){
            let title = Message.slice(0,50);
            this.threadTitle.push(title);
            this.active_thread=this.threadTitle.length-1;
        }
        let parent = this.active_node;
        // let parent = this.active_node === this ? this : this.active_node;
        let new_node = new Node(parent, parent.nodeID, this.current_model, agent_id, Message, Time, 1);
        parent.children.push(new_node);
        this.active_node = new_node; // Update the active node to the new node.
        // this.total_child +=1;
        this.build_prompt(Message);
        return (new_node);
    }


    traverse_node_ser(node, array_accumulator){
        let node_variable={
            model   :node.model,
            agentID :node.agentID,
            message :node.message,
            time    :node.time,
            depth   :node.depth,
            nodeID  :node.nodeID,
            parentID:node.parentID
        }
        array_accumulator.push(node_variable);
        for (let j = 0; j < node.children.length; j++){
            array_accumulator = this.traverse_node_ser(node.children[j], array_accumulator);
        }
        return (array_accumulator);
    }




    serialize(){
        let array_accumulator_master=[];
        let root_variable={
            username    :this.username,
            nodeID      :this.nodeID,
            parentID    :this.parentID,
            threadTitle :this.threadTitle
        }
        array_accumulator_master.push(root_variable);

        for (let j = 0; j < this.children.length;j++){
            let array_accumulator= [];    
            // let array_accumulator= new Map();    // map does not work with JSON.stringify 
            array_accumulator = this.traverse_node_ser(this.children[j],array_accumulator);
            array_accumulator_master.push(array_accumulator);
        }
        // console.log("array_accumulator_master", array_accumulator_master);
        return (array_accumulator_master);    
    }


    traverse_node_deser(node){

    }
  
    async load_local_data_rebuild_graph(){
        try{
            this.children = [];
            this.active_node= this;         // redundant with this.reset()
            this.active_thread=-1;          // redundant with this.reset()

            let array_accumulator = JSON.parse(localStorage.getItem("ConversationRoot"));
            // console.log(array_accumulator);

            /* Parse root data and rebuild root to the storage's value */
            let root_data   = array_accumulator[0];
            this.username   = root_data["username"];
            this.nodeID     = root_data["nodeID"];
            this.parentID   = root_data["parentID"];
            this.threadTitle= root_data["threadTitle"];

            let node_map = new Map();       // build map for faster node identification and rebuilding
            node_map.set(this.nodeID, this);

            /* Parse node's data */
            // console.log(array_accumulator.length);
            for (let k = 1; k< array_accumulator.length;k++){
                for (let j = 0 ; j < array_accumulator[k].length ; j++){
                    let node_data = array_accumulator[k][j];
                    let node_id   = node_data["nodeID"];
                    let node_parent_id = node_data["parentID"];
                    let parent_node = node_map.get(node_parent_id);
                    // console.log("node map66",node_map, node_parent_id, parent_node);
                    let new_node = new Node(parent_node, parent_node.nodeID, node_data["model"], node_data["agentID"], node_data["message"], node_data["time"], 0, node_id);
                    parent_node.children.push(new_node);
                    node_map.set(node_id, new_node);
                    // if (j===0){ 
                    //     this.children.push(new_node);
                    // }
                }
            }
            // console.log(array_accumulator);
            this.reset();
        }catch(error){
            console.log(error);
        }
        

        // console.log(node_map);
        // console.log("lldrg", this.children.length, this.threadTitle.length);
    }
    async periodic_storage(){
        // console.log("PS", this.children.length, this.threadTitle.length);
        let array_accumulator = this.serialize();
        localStorage.setItem("ConversationRoot", JSON.stringify(array_accumulator));
        // console.log("Store ConversationRoot stringified");
    }

    reset(){
        this.active_node = this;
        this.active_thread = -1;
        this.prompt = [];
        // this.prompt = "";
    }


    traverse_node_switch_thread(node, new_prompt, new_messages_chain){
        if (node.depth % 2 === 0){
            new_prompt = new_prompt + "Agent : \n" + node.message + "\n";
        }else{
            new_prompt = new_prompt + "User : \n" + node.message + "\n";
        }

        // this.active_node = node;
        new_messages_chain.push(node);              // Js use reference so we can do this, no need to return it
        if (node.children.length > 0){
            new_prompt = this.traverse_node_switch_thread(node.children[0], new_prompt, new_messages_chain);
        }

        return (new_prompt);
    }
    switch_thread(new_thread_idx){
        this.active_thread = new_thread_idx;
        let new_prompt = "";
        let new_messages_chain=[];
        new_prompt = this.traverse_node_switch_thread(this.children[new_thread_idx], new_prompt, new_messages_chain);

        this.prompt = new_prompt;
        this.active_node=new_messages_chain[new_messages_chain.length-1];

        return (new_messages_chain);
    }

    delete_thread(delete_indx){
        if (this.children.length>1){
            let cl_temp = this.children.length;
            let target_node = this.children[delete_indx];
            this.clean_up(target_node);
            for (let j = delete_indx; j < this.children.length-1;j++){
                this.children[j] = this.children[j+1];                      // shift the children
                this.threadTitle[j]= this.threadTitle[j+1];
            }
            let last = this.children.pop();
            let last2 = this.threadTitle.pop();
            if (delete_indx < this.active_thread){
                this.active_thread = this.active_thread-1;
            }
            // console.log("CL L", cl_temp, this.children.length, this.threadTitle.length);
            // else if (delete_indx === this.active_thread){
                // this.switch_thread(this.children.length - 1);        do this command in the component level, because updating new_messages_chain too
            // }
        }else{
            this.clean_up(this.children[0]);
            this.reset();
            this.children=[];
            this.active_thread=-1;
            this.threadTitle=[];
        }
    }

    clean_up(node){
        while (node.children.length>0){
            let child = node.children.pop();
            child.clean_up();
        }
    }
}

export default ConversationRoot;