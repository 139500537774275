


/* 
Explanation

^[^\s@]+: This part of the expression ensures that the email starts with at least one character that is not a whitespace or an '@' symbol.

@: There must be a single '@' character.

[^\s@]+: After the '@', there must be at least one character that is not a whitespace or an '@'.

\.: This is a literal dot.

[^\s@]+$: The email must end with at least one character after the dot, and these characters cannot be whitespaces or '@'

 */

function CheckValidEmail(email_address){
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email_address);
}

export default CheckValidEmail