






function PasswordStrength(pass_string){
    // let strength = 0;
    // if (0<pass_string.length< 5){
    //     strength = 1;
    // }else if (pass_string.length>=5){
    let char_freq={};
    let char_num = pass_string.length;
    for (let j = 0; j< char_num; j++){
        let char = pass_string.charAt(j);
        if (char in char_freq){
            char_freq[char] +=1;
        }else{
            char_freq[char] =1;
        }
    }

    // console.log(char_freq, Object.keys(char_freq).length, Object.keys(char_freq)); 
    // console.log(pass_string);
    let keys = Object.keys(char_freq);
    let entropy = 0;
    for (let j = 0; j < keys.length; j++){
        let num_key = char_freq[keys[j]]/char_num;
        entropy -= Math.log2(num_key)*num_key;
    }
    // console.log("entropy ", entropy);

    return (entropy);
}

export default PasswordStrength;