
import styles from './user.module.css'
// import HTTPSChunk from '../../services/https_chunk';
import PasswordStrength from '../../utils/password_strength';
import Enum from '../../config/enum';
import CheckValidEmail from '../../utils/valid_email';
import Config from '../../config/config';
import AuthenticationService from '../../services/authentication';

import { useState, useRef, useEffect } from 'react';



function UserManagement(){
    const [user_button_clicked, set_user_button_clicked] = useState(false);        // indicate user button clicked and dropdown enable
    const [user_login_dialogue, set_user_login_dialogue] = useState(false);
    const [user_register_dialogue, set_user_register_dialogue] = useState(false);
    const user_dropdown_ref = useRef(null);
    const user_login_dialogue_ref =useRef(null);
    

    useEffect(()=>{document.removeEventListener('mousedown', mouse_click_after_user_button);},[]);

    const mouse_click_after_user_button = (event)=>{
        if (user_dropdown_ref.current && !user_dropdown_ref.current.contains(event.target)){
            set_user_button_clicked(false);
            document.removeEventListener('mousedown', mouse_click_after_user_button);
        }
    }

    const UserButtonClick = ()=>{
        if (!user_button_clicked){
            set_user_button_clicked(true);
            document.addEventListener('mousedown', mouse_click_after_user_button);
        }
    };

    const OptionLoginClick = ()=>{
        document.removeEventListener('mousedown', mouse_click_after_user_button);
        set_user_button_clicked(false);
        set_user_login_dialogue(true);
    };
    const OptionRegisterClick = ()=>{
        document.removeEventListener('mousedown', mouse_click_after_user_button);
        set_user_button_clicked(false);
        set_user_register_dialogue(true);
    };
    const OptionSettingClick = ()=>{
        document.removeEventListener('mousedown', mouse_click_after_user_button);
        set_user_button_clicked(false);

    };




    return (
        <div>
            <div className={styles.user_button} onClick={UserButtonClick}> 
                <div className={styles.user_button2}></div>
            </div>
            {user_button_clicked && <div className={styles.user_dropdown} ref = {user_dropdown_ref}>
                <div className={styles.user_dropdown_option} onClick={OptionSettingClick}><p>Setting</p></div>
                <div className={styles.user_dropdown_option} onClick={OptionLoginClick}><p>Login</p></div>
                <div className={styles.user_dropdown_option} onClick={OptionRegisterClick}><p>Register</p></div>
            </div>}
            {user_login_dialogue && <LoginMenu set_user_login_register_dialogue={set_user_login_dialogue} type = {Enum.auth_type.login} />}
            {user_register_dialogue && <LoginMenu set_user_login_register_dialogue={set_user_register_dialogue} type={Enum.auth_type.register}/>}

        </div>
    );
}

export default UserManagement;


export function LoginMenu({set_user_login_register_dialogue, type}){
    const [email, set_email] = useState("");
    const [pass, set_pass] = useState("");
    const [re_pass, set_re_pass]= useState("");

    const [adequate_pass, set_adequate_pass] = useState(false);
    const [re_pass_match, set_re_pass_match] = useState(false);
    const [valid_email,   set_valid_email]   = useState(false);
    // const ChunkHTTPS = useRef(new HTTPSChunk()).current;


    useEffect(()=>{
        if (type===Enum.auth_type.register){
            if (pass.length >= Config.Authentication.pass_min_length){
                let pass_entropy = PasswordStrength(pass);
                if (pass_entropy > Config.Authentication.pass_minimum_entropy){
                    set_adequate_pass(true);
                }else{
                    set_adequate_pass(false);
                }
            }else{
                set_adequate_pass(false);
            }
        }
    }, [pass]);

    useEffect(()=>{
        if (re_pass===pass && adequate_pass){
            set_re_pass_match(true);
        }else{
            set_re_pass_match(false);
        }
    },[re_pass]);

    useEffect(()=>{
        if (email.length >= Config.Authentication.email_min_length){
            let email_validity = CheckValidEmail (email);
            // console.log(email_validity);
            if (email_validity){
                set_valid_email(true);
            }else{
                set_valid_email(false);
            }
        }else{
            set_valid_email(false);
        }
    },[email]);

    const CommitClick = ()=>{
        // ServerCall();

        if (type===Enum.auth_type.register){
            if (adequate_pass && re_pass_match && valid_email){
                try{
                    AuthenticationService.Register(email, pass);
                }catch(error){
                    console.log(error);
                }
            }else{
                console.log(`email ${valid_email} pass ${adequate_pass} re pass ${re_pass_match}`);
            }
        }else if (type===Enum.auth_type.login){
            try{
                AuthenticationService.Login(email, pass);
            }catch(error){
                console.log(error);
            }
        }  
        set_email("");
        set_pass("");
        set_re_pass("");
        set_user_login_register_dialogue(false);
        set_adequate_pass(false);
        set_re_pass_match(false);
        set_valid_email(false);
    }

    const CloseClick = ()=>{
        // console.log(email);
        set_email("");
        set_pass("");
        set_re_pass("");
        set_user_login_register_dialogue(false);
        set_adequate_pass(false);
        set_re_pass_match(false);
        set_valid_email(false);
    }

    return(
        <div>
            <div className={styles.user_login_dialogue} >
                <div className={styles.user_login_close_button} onClick={CloseClick}><div className={styles.button_inside}></div></div>
                <div className={styles.user_login_commit_button}onClick={CommitClick}><div className={styles.button_inside}></div></div>

                <div className={styles.user_name_input}  style={{backgroundColor: (valid_email&&(type ===Enum.auth_type.register))? 'rgb(0,230,0)':undefined}} >
                    <input type='email' className={styles.input_field} placeholder={"email"} onChange={(e)=>{set_email(e.target.value)}} autoComplete="email"/>
                </div>
                {type ===Enum.auth_type.login && (
                    <div className={styles.password_input}>
                        <input type='password' className={styles.input_field} placeholder={"password"} onChange={(e)=>{set_pass(e.target.value)}} autoComplete="current-password" />
                    </div>
                )}
                {type ===Enum.auth_type.register && (
                <div >
                    <div className={styles.password_input} style={{backgroundColor: adequate_pass? 'rgb(0,230,0)':undefined}} >
                        <input type='password' className={styles.input_field} placeholder={"password"} onChange={(e)=>{set_pass(e.target.value)}}  />
                    </div>
                    <div className={styles.re_password_input} style={{backgroundColor: (re_pass_match&&adequate_pass)? 'rgb(0,230,0)':undefined}}>
                        <input type='password' className={styles.input_field} placeholder={"re type password"} onChange={(e)=>{set_re_pass(e.target.value)}} autoComplete="new-password" style={{backgroundColor: (type===adequate_pass)? 'rgb(0,230,0)':undefined}} />
                    </div>
                </div>
                )}
            </div>
        </div>
    );
}
