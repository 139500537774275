class Random{
    constructor(){
    }

    generateRandomString(length) {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return result;
    }
    getRandomInt(min, max) {
        min = Math.ceil(min);  // Ensure the minimum is rounded up to the nearest whole number
        max = Math.floor(max); // Ensure the maximum is rounded down to the nearest whole number
        return Math.floor(Math.random() * (max - min + 1)) + min; // The maximum is inclusive and the minimum is inclusive
    }

    generateFastUniqueId() {
        const time = Date.now(); // Milliseconds since the Unix epoch
        const random = Math.random() * 1000000000; // Random number for additional entropy
        return `${time}-${Math.floor(random)}`;
    }
    
}

export default Random