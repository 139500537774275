import { useEffect, useContext, useState, useRef } from 'react';
import ReactMarkdown from 'react-markdown';

import './chat.css'
import ConversationRoot from '../specific/conversation_graph';
import { ChatInput } from "../../contexts/inputs";
import LLMCompletionStream from '../../services/API_dirrect';
import LLMCompletionStreamServer from '../../services/API_server';
import CodeBlock from './code_block';
import { API_context } from '../../contexts/API_context';

function Chat(){

    // const ws = useWebSocket();

    /* Global context */
    const {user_input, set_user_input} = useContext(ChatInput);
    const {model_list, set_model_list} = useContext(ChatInput);
    const {session_title, set_session_title} = useContext(ChatInput);
    const {new_session, set_new_session} = useContext(ChatInput);
    const {session_dropdown_choice, set_session_dropdown_choice} = useContext(ChatInput); 
    
    const {API_websocket} = useContext(API_context);

    /* State that triggers re render upon changes */
    const [last_reply, set_last_reply] = useState("");                              // stream of the last conversation
    const [messages, setMessages] = useState([]);                                   // all the displayed dialogue
    const [stream_status, set_stream_status] = useState(false);                     // false = not streaming llm reply, true =  streaming llm reply. used to enable render streaming object


    
    /* Variable that presistent, but did not triggers re render */
    const Root = useRef(new ConversationRoot(0, "Master")).current;                 // Conversation graph
    const ChatRef = useRef(null);                                                   // for this chat container ref
    const LLMCompletion = useRef(new LLMCompletionStream()).current;                // API
    // const LLMCompletionServer = useRef(new LLMCompletionStreamServer()).current;

    /*
    - Set model list global context from API
    - Set Threads title global context from conversation graph 
    */
    useEffect(() => {  
        
        // if (ws.current && ws.current.readyState === WebSocket.OPEN) {
        //     ws.current.send("message");
        // }
        


        let models = {
            list : LLMCompletion.model_name,
            chosen_model : LLMCompletion.model_name[0],
        };
        set_model_list(models);

        /* session_title dictionary will point to memory location inhabited by Root.threadTitle, so some unexpexted behavior (updates can happen) */
        try{
            Root.load_local_data_rebuild_graph();
        }catch(error){
            console.log(error);
        }
        
        UpdateThreadsTittle();
        // Root.reset();

        return() =>{
            // Root.periodic_storage();
        };

    }, []);




    /* Receive command to delete thread/ session */
    useEffect(()=>{
        if (session_dropdown_choice['conf']==="YES" && session_dropdown_choice['del_indx'] !==-1){
            let delete_idx = session_dropdown_choice['del_indx'];
            console.log("delete ",session_dropdown_choice['del_indx'] );
            set_session_dropdown_choice(previous_value=>({...previous_value, conf:"NO", del_indx: -1})); // chosen  : -1,  

            let active_index = Root.active_thread;
            if (active_index === delete_idx){
                Root.delete_thread(delete_idx);
                Root.reset();
                setMessages([]);
                Root.periodic_storage();
            }else{
                Root.delete_thread(delete_idx);
                Root.periodic_storage();
            }
            UpdateThreadsTittle();  
            
        }

    },[session_dropdown_choice['conf']]);
    

    /* To change the active thread, and only active thread */
    useEffect(()=>{
        let active_thread = session_title["active_thread"];
        // console.log("active", active_thread);
        if (active_thread>=0 && Root.active_thread!==active_thread){
            // console.log("change to ", active_thread);
            let new_messages_chain = Root.switch_thread(active_thread);
            setMessages(new_messages_chain);
            scroll_down(ChatRef, 0);
            scroll_down(ChatRef, 0.05);
            scroll_down(ChatRef, 0.2);
            scroll_down(ChatRef, 1);
            
        }

    }, [session_title["active_thread"]]);


    /* Upgrade the graph with the latest user Input, call stream function */
    useEffect(()=>{
        if (user_input!==""){
            if (user_input["text"]!==""){
                scroll_down(ChatRef,0);

                // console.log(user_input);
                let new_node = Root.add_children(0, user_input["text"],user_input["time"]);

                setMessages(prevMessages => [...prevMessages, new_node]);
                async_stream();

                if (messages.length<=2){
                    UpdateThreadsTittle();              // update thread title if message length < 2. at ml 1 use query, at ml 2 use llm answer 
                }
                set_user_input("");
 
            }
        }
    },[user_input])

    /* Supposed to provide smooth transition from streamed data render to constant data render (last_reply to messages array), probably can be optimized */
    useEffect(()=>{
        if (stream_status ===false && last_reply !==""){
        // if (stream_status ===false && last_reply.length>0){
            const reply_time = new Date().toISOString();
            let new_node = Root.add_children(0, last_reply.replace(/\\n/g, "\n"), reply_time);
            setMessages(prevMessages => [...prevMessages, new_node]);
            
            scroll_down(ChatRef, 0);
            // scroll_down(ChatRef, 0.3);// run 0.5 s after setMessages
            

            /* update thread title if message length < 2. at ml 1 use query, at ml 2 use llm answer  */
            if (messages.length<=2){
                UpdateThreadsTittle();
            }
            set_last_reply("");
            scroll_down(ChatRef, 0);

            Root.periodic_storage();
        }
    },[stream_status])


    /* Triggers scroll down for every new token render in last llm reply  */
    useEffect(() => {
        if (last_reply !== "") {
            scroll_down(ChatRef, 0);
        }
    }, [last_reply]);



    /* Receive new tread/ session Command, then reset Root and messages state */
    useEffect(()=>{
        if (new_session["new_session_clicked"]===true && new_session["new_session_established"]===false ){
            Root.reset();
            setMessages([]);

            let NS={
                new_session_clicked: false,
                new_session_established: true
            }
            set_new_session(NS);
        }
    },[new_session]);


    /* Call LLM API and stream the result into last_reply state */
    async function async_stream() {
        set_last_reply("");
        set_stream_status(true);

        
        let chosen_model_index = LLMCompletion.model_name.indexOf(model_list["chosen_model"]);
        let chosen_model = LLMCompletion.model_list[chosen_model_index];
        // console.log(Root.prompt, "\n", chosen_model);

        // const stream = LLMCompletion.stream(chosen_model, Root.prompt, LLMCompletion.prompt_type.prompt);
        // for await (const token of stream){
        //     set_last_reply(prev => prev + (token.choices[0]?.delta?.content || ""));
        //     // console.log(token.usage, token.model); // token.choices.finish_reason
        // }

        // const stream = LLMCompletion.stream2(chosen_model, Root.prompt, LLMCompletion.prompt_type.prompt);
        const stream = LLMCompletion.stream2(chosen_model, Root.prompt, LLMCompletion.prompt_type.message);
        for await (const token of stream){
            set_last_reply(prev => prev + (token));
            // console.log(token.usage, token.model); // token.choices.finish_reason
        }


        set_stream_status(false);
    }

    /* Function to udate threads's tittle  */
    function UpdateThreadsTittle(){
        // console.log("update tt");
        set_session_title(prev=>({...prev, session_list: Root.threadTitle, active_thread: Root.active_thread}));
    }

    return (
        <div className="chat" ref = {ChatRef}>
            {/* {messages.map((data, index) => <RenderChat key={index} data = {data}/>)} */}
            {messages.map((message) => <RenderChat key={message.depth} data={message} />)}
            
            {stream_status && 
                <div className='chat_llm'>
                    <div className='chat_llm_text'>
                        {/* {last_reply} */}
                        <ReactMarkdown >{last_reply.replace(/\\n/g, "  \n")}</ReactMarkdown>
                        {/* <ReactMarkdown components={{code: CodeBlock}} >{last_reply.replace(/\\n/g, "  \n")}</ReactMarkdown> */}
                    </div>
                </div>}
            <div className='chat_bottom_pad'></div>
        </div>
    );
}
export default Chat;


export function RenderChat({ data }) {
    // let markdownString;
    // if (data.depth % 2 === 0 && data.message.length>0){
    //     markdownString= data.message.join("");
    //     console.log(markdownString);
    // }
    // console.log(data);
    return (
        (data.depth % 2 === 0 ) ? 
        (<div className='chat_llm'>
            <div className='chat_llm_text'>
                {/* <ReactMarkdown components={{code: CodeBlock}}>{data.message.replace(/\n/g, "  \n")}</ReactMarkdown> */}
                <ReactMarkdown components={{code: CodeBlock}}>{data.message.replace(/\n/g, "  \n")}</ReactMarkdown>
            </div>

        </div>):
        (<div className='chat_user'>
            <div className='chat_user_text'>
                {data.message}
            </div>
        </div>)
    );
}

{/* <div className='chat_llm_text'>
    <ReactMarkdown
        components={{
        code: ({ node, inline, className, children, ...props }) => {
            const match = /language-(\w+)/.exec(className || '')
            return !inline && match ? (
            <CodeBlock
                language={match[1]}
                value={String(children).replace(/\n$/, '')}
                {...props}
            />
            ) : (
            <code className={className} {...props}>
                {children}
            </code>
            )
        }
        }}
    >
        {data.message}
    </ReactMarkdown>
</div> */}





// export function scroll_down(ChatRef, timeout){
//     setTimeout(() => {
//         if (ChatRef.current) {
//             /* The one inside setTimeout is the one that will have delayed execution */
//             ChatRef.current.scrollTop = ChatRef.current.scrollHeight;
//         }
//     }, timeout);   // Timeout is in ms
// }


export function scroll_down(ChatRef, timeout) {
    setTimeout(() => {
        if (ChatRef.current) {
            // Calculate the position to scroll to in the document
            const bottomOfChat = ChatRef.current.offsetTop + ChatRef.current.scrollHeight;
            window.scrollTo(0, bottomOfChat - window.innerHeight);
        }
    }, timeout);   // Timeout is in ms
}



