




class LocalStorage{
    static FileList={
        token:"token",
        ConversationRoot:"ConversationRoot",
    }

    static Store(data, file_name){
        localStorage.setItem(file_name, data);
        console.log(`store ${file_name} at local storage`);
    }

    static Load(file_name){
        const value = localStorage.getItem(file_name);
        console.log("load ", value);
        return value;
    }
}



export default LocalStorage;