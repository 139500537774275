import styles from './code_block.module.css'
import { useState, useRef, useEffect } from 'react';



import Prism from 'prismjs';
// import './code/prism-funky.css';
// import './code/prism-okaidia.css';
import './code/prism-tomorrow.css';


/* Import additional languages if needed */
require('prismjs/components/prism-javascript');
require('prismjs/components/prism-python');
require('prismjs/components/prism-rust');
require('prismjs/components/prism-jsx');
require('prismjs/components/prism-c');
require('prismjs/components/prism-cpp');
require('prismjs/components/prism-go');

/* By changing the <div> to a <code> element for small code blocks, you'll avoid the nesting issue while still maintaining the ability to apply your custom styles. */


function CodeBlock({node, inline, className, children, ...props}) {
    // console.log('CodeBlock props:', { node, inline, className, children , ...props });
    if (className){
        // console.log(children.length ,className);
        return <LargeCodeBlock language={className} content={children} />;
    }else if(!className && children.length>50){
        return <LargeCodeBlock language={'language-python'} content={children} unidentified={1}/>;
    }else{
        return (<code className={styles.code_container_small}>{children}</code>);
    }
    return null;
    // const language = className ? className.replace('language-', '') : '';
    // const codeString = String(children).replace(/\n$/, '');
}
export default CodeBlock


export function LargeCodeBlock({language, content, unidentified=0}){
    
    const L = useRef(language.split("-")[1]);
    let L2= language.split("-")[1];
    
    const Content = useRef(content);

    if (unidentified===1){
        L2 = "Unidentified";
    }
    if (language==="language-c"||language==="language-cpp"){
        Content.current = content.replace(/#include </g, "#include &lt;");
    }

    const [copy_state, set_copy_state] = useState("Copy code");

    useEffect(()=>{
        Prism.highlightAll();
    },[language, Content])


    /* Function to copy content to clipboard */
    const copyToClipboard = () => {
        navigator.clipboard.writeText(content).then(() => {
            // alert('Code copied to clipboard!'); // Optional: alert the user that copy was successful
            set_copy_state("Copied!");
            setTimeout(()=>{
                set_copy_state("Copy code");
            }, 2000);
        }, () => {
            set_copy_state("Failed!");
            alert("Failed to copy!");
            setTimeout(()=>{
                set_copy_state("Copy code");
            }, 2000);

        });
    };

    return(
        <div className={styles.code_container}>
            <div className={styles.code_container_header}>
                <p style={{transform:'translateX(1.2vw)'}}>{L2}</p>
                <div className={styles.code_copy_button} onClick={copyToClipboard} ><p style={{transform:'translateX(0.2vw)'}}>{copy_state}</p></div>
            </div>
            <div className={styles.code_container_code}>
                {/* <pre> */}
                    {/* <code dangerouslySetInnerHTML={{__html:(content)}}/> */}
                    {/* <code dangerouslySetInnerHTML={{__html:highlightCode(content, language)}}/> */}
                {/* </pre> */}
                <pre className={`language-${L.current}`}>
                    <code className={`language-${L.current}`} dangerouslySetInnerHTML={{__html: Content.current}}/>
                </pre>
            </div>
        </div>
    );
}









export function highlightCode(code, language) {
    // Basic syntax highlighting for common programming constructs
    const keywords = /\b(function|return|if|else|for|while|class|import|from|const|let|var)\b/g;
    const strings = /(["'`])(?:\\.|[^\\])*?\1/g;
    const numbers = /\b(\d+(?:\.\d+)?)\b/g;
    const comments = /(\/\/.*|\/\*[\s\S]*?\*\/)/g;
    const functions = /(\w+)(?=\s*\()/g;

    // return code
    //     .replace(/&/g, '&amp;')
    //     .replace(/</g, '&lt;')
    //     .replace(/>/g, '&gt;')
    //     .replace(comments, '<span class="comment">$1</span>')
    //     .replace(keywords, '<span class="keyword">$1</span>')
    //     .replace(strings, '<span class="string">$1</span>')
    //     .replace(numbers, '<span class="number">$1</span>')
    //     .replace(functions, '<span class="function">$1</span>');

    return code
    // .replace(/&/g, '&amp;')
    // .replace(/</g, '&lt;')
    // .replace(/>/g, '&gt;')
    // .replace(comments, `<span class="${styles.comment}">$1</span>`)
    // .replace(keywords, `<span class="${styles.keyword}">$1</span>`)
    // .replace(strings, `<span class="${styles.string}">$1</span>`)
    // .replace(numbers, `<span class="${styles.number}">$1</span>`)
    // .replace(functions, `<span class="${styles.function}">$1</span>`);
}

