
import './layout.css'
import SessionList from '../specific/session_list';

function LSidebar(){
    return(
        <div className="l_sidebar">
            <SessionList/>
        </div>
    );
}

export default LSidebar