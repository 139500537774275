import APIDef from "../config/api_def";



class HTTPSChunk{
    constructor(base_url = APIDef.Address.server){
        this.base_url = base_url;
        this.req_type={
            POST:"POST",
            GET :"GET"
        }
        this.dest={
            register:"/register",
            login:"/login"
        }
    }

    async GET(data){
        const url_parameter = new URLSearchParams(data).toString();
        const url = this.base_url+'/?'+url_parameter;
        try{
            const response = await fetch (url);
            const data = await response.text();
            console.log(data);
            // return data;
        }catch(error){
            console.error("error HTTPSChunk POSt", error);
            // return null;
        }
    }

    async POST(dest, data){
        const packet = {
            method  : 'POST',
            headers : {'Content-Type': 'application/json',},
            body    : JSON.stringify(data)
        }
        // Object.assign(packet.headers, additional_header);
        // console.log(packet);
        const url = this.base_url+dest;
        // console.lof("POST")
        try{
            const response = await fetch (url, packet);
            if (!response.ok){
                throw new Error (`HTTPS fetch error status : ${response.status}`); // throw new http error
            }
            // const text     = await response.text();
            const text     = await response.json();
            return text;                                        // return success value
        }catch (error){
            // console.error(`error HTTPSChunk POST", ${error}`); 
            throw new Error (`HTTPSChunk POST error status", ${error}`);                                        // throw new error up the chain
        } 
    }
}

export default HTTPSChunk;






/* The number `200` in `res.writeHead(200, ...)` is an HTTP status code used to indicate the success of the HTTP request. Here's a brief overview of what `200` and other common HTTP status codes represent:

### 1. **200 Series (Success)**
- **200 OK**: The request has succeeded. This is the most common code used to indicate success.
- **201 Created**: The request has succeeded and a new resource has been created as a result.
- **202 Accepted**: The request has been accepted for processing, but the processing has not been completed.
- **204 No Content**: The server successfully processed the request, but is not returning any content.

### 2. **300 Series (Redirection)**
- **301 Moved Permanently**: This response code means that the URI of the requested resource has been changed permanently.
- **302 Found**: This code indicates that the resource is temporarily located at another URI.
- **304 Not Modified**: This is used for caching purposes. It tells the client that the response has not been modified, so the client can continue to use the same cached version of the response.

### 3. **400 Series (Client Error)**
- **400 Bad Request**: The server could not understand the request due to invalid syntax.
- **401 Unauthorized**: Although the HTTP standard specifies "unauthorized", semantically this response means "unauthenticated". That is, the client must authenticate themselves to get the requested response.
- **403 Forbidden**: The client does not have access rights to the content; that is, it is unauthorized, so the server is refusing to give the requested resource.
- **404 Not Found**: The server can not find the requested resource. This is often used when the server does not wish to reveal exactly why the request has been refused.

### 4. **500 Series (Server Error)**
- **500 Internal Server Error**: The server has encountered a situation it doesn't know how to handle.
- **501 Not Implemented**: The request method is not supported by the server and cannot be handled.
- **503 Service Unavailable**: The server is not ready to handle the request. Common causes are a server that is down for maintenance or that is overloaded.

These status codes are part of the HTTP protocol and are a standardized way of informing the client about the result of their request. They play a crucial role in the control flow of client-server communications in web development. */