
import TextInput from '../common/text_input';
import Chat from '../common/chat';

import './layout.css'

function Workspace(){

    
    return(
        <div className="workspace">
            <Chat/>
            <TextInput/>
        </div>
    );
}

export default Workspace