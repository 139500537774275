




class Enum{
    static auth_type={
        login:"login",
        register:"register"
    }

}
export default Enum;