import React, { createContext, useRef, useEffect } from 'react';
import APIServer from '../services/API_server';
import APIDef from '../config/api_def';



export const API_context = createContext();
export function API_contextProvider({children}){

    const API_Server = useRef(null);



    useEffect(()=>{

        // const message = "User: Write a long haiku \nAgent : ";
        // APIServer.stream("meta-llama/llama-3-70b-instruct", message, APIDef.prompt_type.prompt);

        // API_Server.current = new APIServer();
        // API_Server.current.Open("Page Load");
        // return ()=>{
        //     API_Server.current.Close("Page Closed");
        // };


    }, [])


    return (
        <API_context.Provider value={{API_Server}}>
            {children}
        </API_context.Provider>
    );
}





// import React, { createContext, useContext, useEffect, useRef } from 'react';

// // Create Context
// const WebSocketContext = createContext(null);

// // Provider Component
// export const API_contextProvider = ({ children }) => {
//     const ws = useRef(null);

//     useEffect(() => {
//         console.log("init ws")
//         ws.current = new WebSocket('ws://example.com/socket');

//         ws.current.onopen = () => console.log('Connected to WS');
//         ws.current.onclose = () => console.log('Disconnected from WS');

//         return () => {
//             ws.current.close();
//         };
//     }, []);

//     return (
//         <WebSocketContext.Provider value={ws}>
//             {children}
//         </WebSocketContext.Provider>
//     );
// };

// // Custom hook to use WebSocket
// export const useWebSocket = () => {
//     return useContext(WebSocketContext);
// };







export class Openrouter{
    constructor(){
        this.destination = "https://openrouter.ai/api/v1/chat/completions";
        this.api_header = {
            // "Authorization": `Bearer ${process.env.OPENROUTER_API_KEY}`,
            "Authorization": `Bearer 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`,
            // "HTTP-Referer": `${Config.Operation.this_site_loc}`, // Optional, for including your app on openrouter.ai rankings.
            // "X-Title": `${Config.Operation.site_name}`, // Optional. Shows in rankings on openrouter.ai.
            "HTTP-Referer": `https://dev.entrosystem.com}`, // Optional, for including your app on openrouter.ai rankings.
            "X-Title": `entrosystem`, // Optional. Shows in rankings on openrouter.ai.
            "Content-Type": "application/json"
        };
        this.api_method="POST";
    }

    async Fetch(){
        try{
            // const reply = await fetch(this.destination, {
            //     method: this.api_method,
            //     headers: this.api_header,
            //     body:JSON.stringify({
            //         "model":"meta-llama/llama-3.1-8b-instruct:free",
            //         "prompt":"write a good poem",
            //     }),

            // });
            
            const reply = await fetch(this.destination, {
                method: this.api_method,
                headers: this.api_header,
                body: JSON.stringify({
                    model:"meta-llama/llama-3.1-8b-instruct:free",
                    messages: [{ "role": "user", "content": "What is the meaning of life?" }],
                    stream:true
                })
            });

            console.log(reply);

            // const data = await reply.json();
            // console.log(data);
            // console.log(data.choices[0].message.content);
            // for await (const chunk of reply) {
            // //     console.log(chunk);
            // }

            // Check if the ReadableStream is available in the response
            if (reply.body) {
                const reader = reply.body.getReader();
                try {
                    while (true) {
                        const { done, value } = await reader.read();
                        if (done) break;
                        console.log(new TextDecoder().decode(value));
                    }
                } catch (error) {
                    console.error('Failed to read the stream:', error);
                } finally {
                    reader.releaseLock();
                }
            } else {
                console.log('No readable body found in the response');
            }






        }catch(error){
            console.error("Error openrouter API Fetch ", error);
        }

    }
}
