import { useEffect } from 'react';

const useResponsiveDimensions = () => {
    useEffect(() => {
        const handleResize = () => {
            const { innerWidth, innerHeight, screen, devicePixelRatio } = window;
            let ScreenResolutionW, ScreenResolutionH;

            if (screen.orientation.type === 'landscape-primary') {
                ScreenResolutionH = screen.height;
                ScreenResolutionW = innerWidth < innerHeight ? innerWidth : innerHeight;
            } else {
                ScreenResolutionW = screen.width;
                ScreenResolutionH = screen.height;
            }

            // Update CSS variables
            document.documentElement.style.setProperty('--dynamic-width', `${ScreenResolutionW}px`);
            document.documentElement.style.setProperty('--dynamic-widthFull', `${ScreenResolutionH}px`);
            document.documentElement.style.setProperty('--dynamic-height', `${innerHeight}px`);
            document.documentElement.style.setProperty('--dynamic-Zoomlevel', `${devicePixelRatio}`);
        };

        window.addEventListener('resize', handleResize);
        handleResize(); // Set initial dimensions

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
};

export default useResponsiveDimensions;




// useEffect(() => {
//     const handleResize = () => {
//         const zoomLevel = window.devicePixelRatio;
//         // const Screenwidth = window.innerWidth * zoomLevel/1920; // 30% of screen width
//         const ScreenResolutionAvailableW = window.innerWidth
//         const ScreenResolutionAvailableH = window.innerHeight
//         let ScreenResolutionW;
//         let ScreenResolutionH;

//         if (window.screen.orientation['type']=='landscape-primary'){
//             // ScreenResolutionW= window.screen.width
//             ScreenResolutionH= window.screen.height
//             // if the available screen is less than horizontal height (the window is squished in width)else wthe width will follow the height (make the window square)
//             if (ScreenResolutionAvailableW < ScreenResolutionH){
//                 ScreenResolutionW = ScreenResolutionAvailableW;
//             }else{ScreenResolutionW = ScreenResolutionH;}
//             document.documentElement.style.setProperty('--dynamic-width', `${ScreenResolutionW}px`);
//             document.documentElement.style.setProperty('--dynamic-widthFull', `${ScreenResolutionH}px`);
//             document.documentElement.style.setProperty('--dynamic-height', `${ScreenResolutionAvailableH}px`);
//             // console.log("a",window.screen.orientation['type'], ScreenResolutionW,ScreenResolutionH )
//         }else{
//             ScreenResolutionH= window.screen.height
//             ScreenResolutionW= window.screen.width
//             document.documentElement.style.setProperty('--dynamic-width', `${ScreenResolutionW}px`);
//             document.documentElement.style.setProperty('--dynamic-height', `${ScreenResolutionH}px`);
//             // console.log("b",window.screen.orientation['type'], ScreenResolutionW,ScreenResolutionH )
//         }
//         // console.log(zoomLevel,ScreenResolutionW,ScreenResolutionH)
//         // console.log(DataAnalysisFiles);
//         document.documentElement.style.setProperty('--dynamic-Zoomlevel', `${zoomLevel}`);
//     };
//     // Attach the event listener
//     window.addEventListener('resize', handleResize);
//     // Call handleResize to set the initial size
//     handleResize();
//     // Cleanup
//     return () => {
//       window.removeEventListener('resize', handleResize);
//     };
// }, []);
