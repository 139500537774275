
// class ClientServerAPI_def{
class APIDef{
    static UserRegister_reply_state = {
        Error_CheckExist_query:"Error_CheckExist_query",
        Username_already_exist:"Username_already_exist",
        Failed_to_insert_to_database:"Failed_to_insert_to_database",
        Username_successfully_registered:"Username_successfully_registered",
        Password_is_not_adequate:"Password_is_not_adequate",
    }

    static AuthenticationLogin_reply_state = {
        Error_PassQuery_query:"Error_PassQuery_query",
        Username_did_not_exist:"Username_did_not_exist",
        Password_did_not_match:"Password_did_not_match",
        Succesfull_login:"Succesfull_login"
    }

    static login_type={
        new_login:"new_login",
        token_login:"token_login",
    }

    static Address={
        // server:'https://server.entrosystem.com:445',
        // completion:'https://server.entrosystem.com:445/completion',
        server:'https://dev.entrosystem.com/api',
        completion:'https://dev.entrosystem.com/api/completion',
    }

    static prompt_type = {
        prompt:"prompt",
        message:"message"
    };


}



export default APIDef