import { useContext } from 'react';
import { ChatInput } from '../../contexts/inputs';

import DropDown from '../common/drop_down';
import UserManagement from '../common/user';

// import styles from'./header.module.css'      // the child did not inherit the styles
import './header.css'


function Header(){
    const {new_session, set_new_session} = useContext(ChatInput);

    function NewSessionClick(){
        let NS={
            new_session_clicked: true,
            new_session_established: false
        }
        set_new_session(NS);
        // console.log("NS")
    }

    return(
        // <div className="styles.header">
        <div className="header">
            <DropDown/>
            <div className='new_chat_button1' onClick={NewSessionClick}><div className='new_chat_button2'><div className='new_chat_button3'></div></div></div>
            <UserManagement/>
        </div>
    );
}

export default Header;